import React from "react";
import _ from "lodash";
import classnames from "classnames";
import CountUp from "react-countup";
import styles from "./index.module.scss";
import AppContainer from "../AppContainer";
import Loader from "../Loader";
import Button from "../Button";
import List from "../List";
import { TextInput } from "../Inputs";
import TrashIcon from "../icons/Trash.svg";
import ScanIcon from "../icons/Scan.svg";
import Card from "../Card";
import Modal from "react-responsive-modal";
import QRCode from "qrcode.react";
import { setParams } from "../../utils/location";

export default ({
  appStyles,
  T,
  classNames,
  currencySymbol: appCurrencySymbol,
  user: {
    externalGiftCards,
    addExternalGiftCardState,
    removeExternalGiftCardState,
  },
  addExternalGiftCardToAccount,
  removeExternalGiftCardFromAccount,
  getExternalGiftCards,
  resetAddExternalGiftCardToAccount,
  openInputFields,
}) => {
  const [openAddGiftCard, setOpenAddGiftCard] = React.useState(openInputFields);
  const [newGiftCard, setNewGiftCard] = React.useState({});
  const [openQRCodeModal, setOpenQRCodeModal] = React.useState({ open: false, number: undefined });
  if (
    addExternalGiftCardState &&
    !_.isEmpty(newGiftCard) &&
    addExternalGiftCardState.sending
  ) {
    setNewGiftCard({});
    setOpenAddGiftCard(openInputFields);
  } else if (
    (addExternalGiftCardState && addExternalGiftCardState.sent) ||
    (removeExternalGiftCardState && removeExternalGiftCardState.sent)
  ) {
    resetAddExternalGiftCardToAccount();
    getExternalGiftCards();
  }

  console.log("externalGiftCards", externalGiftCards);

  return (
    <div className={classNames}>
      <div style={{ display: "flex", flexDirection: "column", margin: 16 }}>
        <span
          style={{
            color: appStyles.titleOnBackgroundColor,
            textAlign: "center",
            fontSize: "1.3rem",
          }}
        >
          {T("{{business_name}} Gift Cards")}
        </span>
        <List rtl>
          {externalGiftCards &&
            !externalGiftCards.sending &&
            externalGiftCards.data &&
            _.map(
              _.filter(externalGiftCards.data.giftCards, (giftCard) =>
                _.get(giftCard, "enabled"),
              ),
              (giftCard) => {
                return (
                  <List.Item
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row", maxHeight: "40px", cursor: "pointer" }}
                      onClick={() =>
                        setOpenQRCodeModal({ open: true, number: _.get(giftCard, "cardNumber") })
                      }>
                      <span style={{ display: "flex", flexDirection: "row" }} >
                        <ScanIcon
                          role="button"
                          aria-label={T("Show QR Code")}
                          style={{ paddingRight: "10px" }}
                        />
                      </span>

                      <span style={{ display: "flex", flexDirection: "column" }}>
                        <small className={styles.GiftCardLabel}>
                          {T("Card Number")}
                        </small>

                        {_.get(giftCard, "cardNumber")}
                      </span>
                    </div>
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      <small className={styles.GiftCardLabel}>
                        {T("Balance")}
                      </small>
                      {appCurrencySymbol}
                      {_.get(giftCard, "balance")}
                    </span>

                    <span>
                      <TrashIcon
                        onClick={() =>
                          removeExternalGiftCardFromAccount({
                            cardNumber: _.get(giftCard, "cardNumber"),
                          })
                        }
                        className={styles.Clickable}
                        role="button"
                        aria-label={T("REMOVE")}
                      />
                    </span>
                  </List.Item>
                );
              },
            )}
        </List>
        {((addExternalGiftCardState && addExternalGiftCardState.sending) ||
          (externalGiftCards && externalGiftCards.sending)) && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <Loader appStyles={appStyles} />
            </div>
          )}
        {openAddGiftCard &&
          (!addExternalGiftCardState || !addExternalGiftCardState.sending) && (
            <Card appStyles={appStyles}>
              <Card.Title light appStyles={appStyles}>
                {T("Enter your gift card number")}
              </Card.Title>
              <Card.Content>
                <div style={{ marginBottom: 6 }}>{T("Gift Card Number")}</div>
                <TextInput
                  onValid={(value) =>
                    setNewGiftCard({ ...newGiftCard, cardNumber: value })
                  }
                  appStyles={appStyles}
                  validator={(input) => !_.isEmpty(input)}
                  errorMessage={T("Please enter a valid gift card number")}
                />
                <div style={{ marginBottom: 6 }}>{T("Gift Card PIN")}</div>
                <TextInput
                  onValid={(value) =>
                    setNewGiftCard({ ...newGiftCard, cardPIN: value })
                  }
                  appStyles={appStyles}
                  noCheckmark
                />
                <Button
                  style={{ margin: 0, marginTop: "0px" }}
                  appStyles={appStyles}
                  centered
                  onClick={() => {
                    if (!_.isEmpty(newGiftCard)) {
                      addExternalGiftCardToAccount(newGiftCard);
                    }
                  }}
                >
                  {T("Connect Gift Card")}
                </Button>
              </Card.Content>
            </Card>
          )}

        {(!addExternalGiftCardState || !addExternalGiftCardState.sending) &&
          !openAddGiftCard && (
            <Button
              style={{ margin: 0, marginTop: "16px" }}
              appStyles={appStyles}
              centered
              onClick={() => {
                setOpenAddGiftCard(true);
              }}
            >
              {T("Enter a Gift Card")}
            </Button>
          )}

        <Modal
          open={openQRCodeModal.open}
          onClose={() => {
            setOpenQRCodeModal({ open: false, number: undefined });
          }}
          center
          classNames={{ modal: styles.Modal }}
          styles={{
            modal: {
              background: appStyles.cardBackgroundColor,
              borderRadius: 5,
              width: "80%",
              minHeight: "400px",
              textAlign: "center",
              direction: appStyles.rtl && "rtl",
              ...(appStyles.bodyFont && { fontFamily: appStyles.bodyFont }),
              ...(appStyles.bodyFontWeight && {
                fontFamily: appStyles.bodyFontWeight,
              }),
            },
            overlay: { background: "rgba(0, 0, 0, 0.5)" },
          }}
        >
          <h2 style={{ ...appStyles.CardTitle, textAlign: "center" }}>
            {T("Gift Card")}
          </h2>
          <h4 style={{ ...appStyles.CardTitle, textAlign: "center" }}>
            {T("Scannable QR Code")}
          </h4>
          
          <div className={styles.Wrapper}>
            {_.get(openQRCodeModal, "number") && (
              <QRCode
                className="fs-unmask"
                value={
                  openQRCodeModal.number
                }
                style={{ height: "100%", width: "100%", maxWidth: "300px", maxHeight:"300px"}}
              />)}

          </div>

          <Button
              style={{ margin: 0, marginTop: "16px" }}
              appStyles={appStyles}
              centered
              to = {setParams("/gift-card", {"reloadCardNumber": openQRCodeModal.number, onCloseTarget: "/"})}
            >
              {T("Add funds to this card")}
            </Button>
        </Modal>
      </div>
    </div>
  );
};
